import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import './plugins/axios';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import router from './router';
import store from '@/store/index';
import './registerServiceWorker';
import dateFilter from '@/filters/date.filter';

Vue.use(require('vue-moment'));

let PAGE_TITLE="Сервис загрузки деклараций"

Vue.config.productionTip = false;

Vue.filter('date', dateFilter);

router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
      document.title = PAGE_TITLE;
  });
});

Object.defineProperty(Vue.prototype,"$bus",{
	get: function() {
		return this.$root.bus;
	}
});

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
  data: {
		bus: new Vue({})
	}
}).$mount('#app');
