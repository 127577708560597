import axios from 'axios';
import { MutationTree } from 'vuex';
import { UserState, LOGIN, LOGOUT, LOGIN_ERROR } from './types';

export const mutations: MutationTree<UserState> = {
  [LOGIN](state, { id, jwt_token, login }) {
    state.user_id = id;
    state.jwt_token = jwt_token;
    state.login = login;
  },
  [LOGOUT](state) {
    state.user_id = null;
    state.jwt_token = null;
    state.login = null;
  },
  [LOGIN_ERROR](state) { },
};
