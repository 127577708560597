'use strict';

import Vue from 'vue';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import router from '../router';
import store from '../store/index';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

// tslint:disable-next-line: variable-name
const _axios = axios.create(config);

axios.interceptors.request.use(function (config) {
  let token = store.getters['auth/user'].jwt_token;
  if (token)
    config.headers = {
      'Authorization': 'Bearer ' + token
    }
  return config;
},
  function (error) {
    return Promise.reject(error);
  });

_axios.interceptors.request.use(
  // Do something before request is sent
  (config: AxiosRequestConfig) => {
    let token = store.getters['auth/user'].jwt_token;
    if (token)
      config.headers = {
        'Authorization': 'Bearer ' + token
      }
    return config
  },
  // Do something with request error
  (error: any) => Promise.reject(error),
);

// Add a response interceptor
_axios.interceptors.response.use(
  // Do something with response data
  (response: any) => response,
  // Do something with response error
  function (error) {
    if (error.response.status === 401) {
      store.commit('auth/logout');
    }
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  // Do something with response data
  (response: any) => response,
  // Do something with response error
  function (error) {
    if (error.response.status === 401) {
      router.push('/login');
    }
    return Promise.reject(error);
  },
);

function AxiosPlugin(vue: typeof Vue, options?: any): void {
  vue.prototype.$axios = _axios;
}

declare module 'vue/types/vue' {
  interface Vue {
    $axios: AxiosInstance;
  }
}

Vue.use(AxiosPlugin);

export default AxiosPlugin;
