import { ActionTree } from 'vuex';
import axios from 'axios';
import { UserState, LOGIN, LOGOUT, LOGIN_ERROR } from './types';
import { RootState } from '../types';
import router from '@/router';

export const actions: ActionTree<UserState, RootState> = {
  login({ commit, }, {login, password}): any {
    return axios.post('/login', {login, password})
    .then((resp)=>{
      commit(LOGIN, resp.data);
    });
  },
  logout({ commit }): any {
    //return axios.post('/logout').then((resp)=>{
      commit(LOGOUT);
      router.push('/login');
    //});
  },
};
