import axios from 'axios';
import Vue from 'vue';
import Router from 'vue-router';
import store from './store/index';

Vue.use(Router);

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/Login.vue'),
    },
    {
      path: '/declaration',
      name: 'declaration',
      component: () => import('./views/Declaration.vue'),
      alias: '/',
    },
  ],
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = store.getters['auth/user'];
  if(!authRequired){
    next();
    return;
  }
  if (loggedIn.user_id) {
    axios.get('api/login/check').then(()=>next());
  } else {
    next('/login');
  }
})

export default router;