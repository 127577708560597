import internal from "stream";

export interface UserState {
  user_id: number | null;
  login: string | null;
  jwt_token: string | null;
}

export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const LOGIN_ERROR = 'login_error'
