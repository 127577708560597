






































import { Component, Vue } from 'vue-property-decorator';
import store from './store/index';

@Component({})
export default class App extends Vue {
  public clipped: boolean = true;
  public drawer: boolean = true;
  public miniVariant: boolean = false;
  public right: boolean = true;
  public title: string = 'Сервис загрузки медико-санитарных деклараций';
  public popupVisible: boolean = false; 
  public popupMessage: string = "Ошибка";
  public popupType: string = "error";

  public logout(){
    store.dispatch('auth/logout');
  }

  public created() {
    (this as any).$bus.$on('popup', this.showPopup);
  }

  private showPopup({message, type}: { message: string, type: string}) {
    this.popupMessage = message;
    this.popupType = type;
    this.popupVisible = true;
  }
}
